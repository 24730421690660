:root {
  --color-1: #28201c;
  --color-2: #9f461d;
  --color-3: #ffc000;
  --color-4: #32b9c0;
  --color-5: #4400ff;
}
.site-grid {
  padding: 8px;
  min-height: 100vh;
}

.site-style {
  background-image: linear-gradient(var(--color-1), var(--color-2));
  color: var(--color-3);
  font-family: 'Mystery Quest';
}
.link {
  color: var(--color-4);
}
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
/* Font Awesome Styles */
.fa-facebook {
  background: #0866FF;
  color: white;
}
.fa {
  padding: 5px;
  font-size: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  text-decoration: none;
  border-radius: 50%;
}


/* For tablets: */
@media only screen and (min-width: 600px) {
  .col-s-1 {
    width: 8.33%;
  }

  .col-s-2 {
    width: 16.66%;
  }

  .col-s-3 {
    width: 25%;
  }

  .col-s-4 {
    width: 33.33%;
  }

  .col-s-5 {
    width: 41.66%;
  }

  .col-s-6 {
    width: 50%;
  }

  .col-s-7 {
    width: 58.33%;
  }

  .col-s-8 {
    width: 66.66%;
  }

  .col-s-9 {
    width: 75%;
  }

  .col-s-10 {
    width: 83.33%;
  }

  .col-s-11 {
    width: 91.66%;
  }

  .col-s-12 {
    width: 100%;
  }
}

/* For desktop: */
@media only screen and (min-width: 768px) {
  .col-1 {
    width: 8.33%;
  }

  .col-2 {
    width: 16.66%;
  }

  .col-3 {
    width: 25%;
  }

  .col-4 {
    width: 33.33%;
  }

  .col-5 {
    width: 41.66%;
  }

  .col-6 {
    width: 50%;
  }

  .col-7 {
    width: 58.33%;
  }

  .col-8 {
    width: 66.66%;
  }

  .col-9 {
    width: 75%;
  }

  .col-10 {
    width: 83.33%;
  }

  .col-11 {
    width: 91.66%;
  }

  .col-12 {
    width: 100%;
  }
}
