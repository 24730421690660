
.topnav a:hover, p:hover {
  color: var(--color-2
  );
  cursor: pointer;
}

.btn-play{
  background: none;
}

.burger {
  display: block;
  color: var(--color-3);
}
.burger:hover{
  color: var(--color-2)
}

.topnav a, p {
  display: block;
  color: var(--color-3);
  font-size: 17px;
}

.topnav button {
  display: block;
  background-color: var(--color-1);
  color: var(--color-3);
  font-size: 17px;
}

.sub-menu{
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.hidden{
  display: none;
}

@media screen and (min-width: 600px) {
  .burger {
    display: none;
  }
  .sub-menu{
    min-width: 50vw;
    display: grid;
    grid-auto-flow: column;
  }
  .topnav{
    overflow: hidden;
  }
  .topnav a, p{
    display: inline;
  }
}

@media screen and (min-width: 1000px) {
  .sub-menu{
    min-width: 70vw;
    display: grid;
    grid-auto-flow: column;
    text-align: center;
  }
  .topnav{
    overflow: hidden;
  }
  .topnav a,p {
    padding: 5vw;
  }


}