
.gigs-head{
    margin-top: 20px; 
}

.gigs-list{
    margin-top: 20px;
    display: grid;
    grid-template-columns: 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw;

}
.gig-items{
    grid-column-start: 1;
    font-size:small;
    grid-column-end: 12;
    margin-bottom: 10px;
}

.gig-list-item{
    margin-bottom: 10px;
}
.loader {
  font-size:xx-large;
    animation: spin 2s linear infinite;
  }
  .loader-text{
    animation: fade 3s linear infinite ;
  }
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  @keyframes fade {
    0%{color: var(--color-1);}
    50%{color: var(--color-3);}    
    100%{color: var(--color-1);}
  }
@media screen and (min-width: 1000px) {
 .gig-items{
grid-column-start: 4;
font-size: large;
  }
  .gigs-head{
   text-align: center;
  }
}
  
  
 