



.footer{
padding-top:5vh;
    display: grid;
    grid-template-columns: 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw;
  
}
.footer-text{
    grid-column-start: 1;
    grid-column-end: 12;
}
@media screen and (min-width: 1000px) {
.footer-text{
    grid-column-start: 4;
}
}