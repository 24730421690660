.tunes-head {
  margin-top: 20px;
}
.tunes-list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-columns: min-content;
}
.tune-items {
  font-size: small;
  margin-bottom: 5px;
  margin-left: 25px;
}
.tune-list-item {
  margin-bottom: 5px;
}


@media screen and (min-width: 600px) {
    .tunes-list {
        grid-template-columns: 1fr 1fr;
      }

  .tunes-head {
    text-align: center;
  }
  .tune-items {
    font-size: large;
  }
}

@media screen and (min-width: 1000px) {
    .tunes-list {
        grid-template-columns: 1fr 1fr 1fr;
      }
    }

    @media screen and (min-width: 1500px) {
        .tunes-list {
            grid-template-columns: 1fr 1fr 1fr 1fr;
          }
        }    