.homepage-text {
  display: grid;
  grid-template-columns: 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw 8.33vw;
}
.logo {
  display: block;
  text-align: center;
}
.logo-img {
  margin-top: 25px;
  max-height: 20vh;
}
.head {
  max-width: 100vw;
  grid-column-start: 1;
  grid-column-end: 11;
  margin-bottom: 25px;
}
.subhead {
  grid-column-start: 4;
  grid-column-end: 12;
  margin-bottom: 50px;
  text-align: end;
}
.strap {
  grid-column-start: 1;
  grid-column-end: 12;
  margin-bottom: 50px;
}
.contact {
  grid-column-start: 1;
  grid-column-end: 12;
  margin-bottom: 25px;
  font-family:Arial, Helvetica, sans-serif;
}
.email {
  color: var(--color-5);
  grid-column-start: 1;
  grid-column-end: 12;
  margin-bottom: 25px;
  font-family:Arial, Helvetica, sans-serif;
}

@media screen and (min-width: 600px) {
  .logo {
    max-height: 25vh;
    text-align: left;
    padding-left: 25vw;
  }
  .subhead {
    grid-column-start: 6;
    text-align: start;
  }
}

@media screen and (min-width: 1000px) {
  .logo {
    max-width: 20vh;
    text-align: centre;
    padding-left: 40vw;
    padding-bottom: 25px;
  }

  .head {
    text-align: start;
  }
.head,.strap,.contact,.email{
    grid-column-start: 4;
}



}
